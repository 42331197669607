import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import richTextParserNews from '../richTextParserNews'
import ImageVideo from '../components/ImageVideo'

const Template = props => {
  const { pageContext } = props
  const bgstyle = { 'background-color': pageContext.featuredColor }
  return (
    <Layout>
      <SEO title={ pageContext.title } />
      <div className="px-site mt-40 mb-12">
        <h2 className="h2 pb-12 border-b border-solid">{pageContext.category}</h2>
      </div>
      {pageContext.featuredImage
        ? (
          <div className="mb-16">
            <div className="md:flex-row-reverse flex flex-col">
              <div className="w-full max-h-[90vh] aspect-[5/4]">
                <ImageVideo file={pageContext.featuredImage} className="h-full" imgClassName="h-full" />
              </div>
              <div className="px-site bg-salmon flex items-center py-8" style={bgstyle}>
                <div className="md:max-w-lg md:w-full">
                  <h1 className="h3 mb-4">{ pageContext.title }</h1>
                  {pageContext.description && (
                    <p className="p--bold">{ pageContext.description.description }</p>
                  )
                  }
                </div>
              </div>
            </div>
          </div>
        )
        : (
          <div className="px-site mb-12">
            <h1 className="h3 mb-4">{ pageContext.title }</h1>
            {pageContext.description && (
              <p className="p--bold">{ pageContext.description.description }</p>
            )
            }
          </div>
        )
      }
      {pageContext.content && (
        <article className="px-site">
          <div className="prose-p:font-light prose-p:max-w-prose prose-a:underline-offset-1 prose-a:decoration-purple prose-a:decoration-2 prose-a:underline prose-a:align-baseline prose-ul:list-disc prose-ol:list-decimal prose-h2:my-8 prose-headings:max-w-screen-xl prose-lg" >{richTextParserNews(pageContext.content)}</div>
        </article>
      )}
    </Layout>
  )
}

export default Template
